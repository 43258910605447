import React from "react"

import { Layout } from "../../components/Layout"
import { SEO } from "../../components/SEO"

import "./about.scss"
import sockPrototypeImg from "../../images/sock-prototype.jpg"

const AboutPage = () => (
  <Layout>
    <SEO
      description="We were tired of buying no-shows that were too thin, ripped too
          easily, and slipped off our heels. So, we set out to create the best
          no-show sock."
      title="About"
    />
    <section className="container">
      <div className="about-header">
        <img src={sockPrototypeImg} alt="Sock Prototype" />
      </div>
      <div className="about-mission">
        <h1>Our Mission</h1>
        <p>
          We were tired of buying no-shows that were too thin, ripped too
          easily, and slipped off our heels. So, we set out to create the best
          no-show sock. After a successful{" "}
          <a
            href="https://www.kickstarter.com/projects/bryantran/specter-no-shows-functional-socks-for-every-occasion"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kickstarter campaign
          </a>
          , our product is now available for sale. Thanks for all your support
          and help in transforming this idea into a reality.
        </p>
        <p>
          This is only the beginning. We're continually working to create
          lasting products that solve simple problems. Why? We're
          entrepreneurial people by nature, and we thrive by constantly
          building. In the meantime, sign up for our mailing list below, and
          keep an eye out for our future products.
        </p>
        <p>
          Cheers,
          <br />
          The Specter Team
        </p>
      </div>
    </section>
  </Layout>
)

export default AboutPage
